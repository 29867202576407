<template>
  <StatusPage>
    <template #icon><IconClockBrand /></template>
    <template #title>СКОРО!</template>
    <template #desc>Данная страница находится в разработке и совсем скоро станет доступна.</template>
    <template #actions><BaseButton @click="back" color-primary><IconArrowLeft />Назад</BaseButton></template>
  </StatusPage>
</template>

<script setup>
import StatusPage from '@/components/layouts/StatusPage'
import BaseButton from '@/components/common/BaseButton'
import IconClockBrand from '@/components/icons/IconClockBrand'
import { useSafeGoBack } from '~/composables/router'
import { IconArrowLeft } from '@tabler/icons-vue'

const back = useSafeGoBack()
</script>

<style scoped lang="scss">

</style>
