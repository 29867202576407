<template>
  <footer :class="{ 'mobile-hidden': $route.meta.mobile?.footer === false }">
    <div class="horiz-container">
      <div class="content">
        <div class="info">
          <Logo class="logo"/>
          <div class="soc-links" v-if="false">
            <a href="#">
              <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M21.579 7.77133C21.719 7.30633 21.579 6.96533 20.917 6.96533H18.724C18.166 6.96533 17.911 7.26033 17.771 7.58433C17.771 7.58433 16.656 10.3033 15.076 12.0663C14.566 12.5793 14.333 12.7413 14.055 12.7413C13.916 12.7413 13.714 12.5793 13.714 12.1143V7.77133C13.714 7.21333 13.553 6.96533 13.088 6.96533H9.642C9.294 6.96533 9.084 7.22333 9.084 7.46933C9.084 7.99733 9.874 8.11933 9.955 9.60733V12.8353C9.955 13.5423 9.828 13.6713 9.548 13.6713C8.805 13.6713 6.997 10.9423 5.924 7.81833C5.715 7.21133 5.504 6.96633 4.944 6.96633H2.752C2.125 6.96633 2 7.26133 2 7.58533C2 8.16733 2.743 11.0473 5.461 14.8563C7.273 17.4573 9.824 18.8673 12.148 18.8673C13.541 18.8673 13.713 18.5543 13.713 18.0143V16.0483C13.713 15.4223 13.846 15.2963 14.287 15.2963C14.611 15.2963 15.169 15.4603 16.47 16.7133C17.956 18.1993 18.202 18.8663 19.037 18.8663H21.229C21.855 18.8663 22.168 18.5533 21.988 17.9353C21.791 17.3203 21.081 16.4253 20.139 15.3663C19.627 14.7623 18.862 14.1123 18.629 13.7873C18.304 13.3683 18.398 13.1833 18.629 12.8113C18.63 12.8123 21.301 9.05033 21.579 7.77133Z"/>
              </svg>
            </a>
            <a href="#">
              <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                <path
                  d="M21.593 8.11951C21.363 7.26151 20.688 6.58451 19.831 6.35351C18.265 5.92351 12 5.91651 12 5.91651C12 5.91651 5.73599 5.90951 4.16899 6.32051C3.32899 6.54951 2.63499 7.24151 2.40299 8.09851C1.98999 9.66451 1.98599 12.9125 1.98599 12.9125C1.98599 12.9125 1.98199 16.1765 2.39199 17.7265C2.62199 18.5835 3.29699 19.2605 4.15499 19.4915C5.73699 19.9215 11.985 19.9285 11.985 19.9285C11.985 19.9285 18.25 19.9355 19.816 19.5255C20.672 19.2955 21.35 18.6195 21.583 17.7625C21.997 16.1975 22 12.9505 22 12.9505C22 12.9505 22.02 9.68551 21.593 8.11951ZM9.99599 15.9215L10.001 9.92151L15.208 12.9265L9.99599 15.9215Z"/>
              </svg>
            </a>
          </div>
        </div>
        <nav class="footer-nav">
          <NuxtLink :to="{name: 'mentor-list'}" class="nav-link">Менторы</NuxtLink>
          <NuxtLink :to="{name: 'project-list'}" class="nav-link">Проекты</NuxtLink>
          <NuxtLink :to="{name: 'specialist-list'}" class="nav-link">Специалисты</NuxtLink>
          <NuxtLink v-if="user" :to="{name: 'mentor-calendar'}" class="nav-link">Календарь</NuxtLink>
        </nav>
        <div class="buttons">
          <BaseButton v-if="!user" :to="{name: 'auth-login'}">Войти</BaseButton>
          <BaseButton v-if="!user?.is_mentor" :to="becomeMentorRoute" :outlined="true">Стать ментором</BaseButton>
        </div>
      </div>
      <div v-if="theme === 'dark'" class="org-info">
        <div class="org-block">
          <div class="org-info__links">
            <NuxtLink :to="{name: 'privacy'}" class="link">Политика Конфиденциальности</NuxtLink>
            <NuxtLink :to="{name: 'offer'}" class="link">Оферта</NuxtLink>
            <NuxtLink :to="{name: 'requisites'}" class="link">Реквизиты</NuxtLink>
            <NuxtLink :to="{name: 'reference'}" class="link">Справка</NuxtLink>
          </div>
          <div class="org">
            <div>
             <span class="org-contact">
             <a href="mailto:hello@mentoring.digital">hello@mentoring.digital</a>
             <a href="tel:+7 499 643-46-50">+7 499 643-46-50</a>
             </span><br>
              <span class="org-name">© {{ year }} ООО «ИВЕНТ ХАБ» <a class="org-inn" href="javascript: void(0)">(7728470702/772801001)</a></span>
            </div>
          </div>
        </div>
        <div class="org-info-block org-info-line">
          <div class="org-info-block-item">
            <a href="https://reestr.digital.gov.ru/reestr/2307992/" target="_blank"><img src="../../assets/img/platform-rpo-light.svg" alt=""></a>
          </div>
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/Tinkoff-Education-logo-light.svg" alt=""></a>
          </div>
        </div>
        <div class="org-info-block org-info-bottom-line">
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/mastercard.svg" alt=""></a>
          </div>
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/visa-light.svg" alt=""></a>
          </div>
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/mir-light.svg" alt=""></a>
          </div>
        </div>
      </div>
      <div v-else class="org-info">
        <div class="org-block">
          <div class="org-info__links">
            <NuxtLink :to="{name: 'privacy'}" class="link">Политика Конфиденциальности</NuxtLink>
            <NuxtLink :to="{name: 'offer'}" class="link">Оферта</NuxtLink>
            <NuxtLink :to="{name: 'requisites'}" class="link">Реквизиты</NuxtLink>
            <NuxtLink :to="{name: 'reference'}" class="link">Справка</NuxtLink>
          </div>
          <div class="org">
            <div>
             <span class="org-contact">
             <a href="mailto:hello@mentoring.digital">hello@mentoring.digital</a>
             <a href="tel:+7 499 643-46-50">+7 499 643-46-50</a>
             </span><br>
              <span class="org-name">© {{ year }} ООО« ИВЕНТ ХАБ» <a class="org-inn" href="javascript: void(0)">(7728470702/772801001)</a></span>
            </div>
          </div>
        </div>
        <div class="org-info-block org-info-line">
          <div class="org-info-block-item">
            <a href="https://reestr.digital.gov.ru/reestr/2307992/" target="_blank"><img src="../../assets/img/platform-rpo-dark.svg" alt=""></a>
          </div>
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/Tinkoff-Education-logo-no-bg-left-rus.svg" alt=""></a>
          </div>
        </div>
        <div class="org-info-block org-info-bottom-line">
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/mastercard.svg" alt=""></a>
          </div>
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/visa.svg" alt=""></a>
          </div>
          <div class="org-info-block-item">
            <a href="#"><img src="../../assets/img/mir.svg" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import BaseButton from '@/components/common/BaseButton'
import Logo from '@/components/parts/Logo'
import {useBecomeMentorRoute} from '~/composables/router'

const theme = inject('theme')

const user = inject('user')
const year = useState(() => new Date().getFullYear())

const becomeMentorRoute = useBecomeMentorRoute()
</script>

<style scoped lang="scss">
footer {
  padding: 63px 0 44px;
  border-top: 1px solid var(--color-separator);
  @media (max-width: 920px) {
    padding: 24px 0;
  }
}

.footer-nav {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
}

.footer-nav a {
  display: inline-block;
}

@media (max-width: 920px) {
  .footer-nav {
    justify-content: flex-start;
    flex-direction: column;
  }
}

.logo {
  width: 197px;
  height: 45px;
}

.content {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: 920px) {
    flex-direction: column;
    align-items: stretch;
  }

  & .info {
    position: relative;
    @media (max-width: 920px) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid var(--color-separator);
    }

    & .soc-links {
      margin-left: 67px;
      margin-top: -10px;
      display: flex;
      align-items: center;

      & > a {
        margin-right: 10px;
      }

      @media (max-width: 920px) {
        margin-left: 54px;
        margin-top: 0;
      }
    }
  }

  & nav {
    row-gap: 13px;
    flex: 1;
    padding-left: 94px;
    padding-right: 70px;
    @media (max-width: 920px) {
      padding: 0;
    }
  }

  & .nav-link {
    grid-column: span 3;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-elements-secondary);
    @media (max-width: 920px) {
      grid-column: span 6;
    }
  }

  & .buttons {
    display: flex;
    gap: 19px;
    @media (max-width: 920px) {
      margin-top: 24px;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
    }
  }
}
.org-inn {
  cursor: unset;
  text-decoration: none;
}
.org-inn:hover {
  opacity: 1;
}
.org-info {
  //margin-left: 67px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-elements-secondary);
  margin-top: 25px;
  gap: 24px;
  @media (max-width: 1130px) {
    flex-wrap: wrap;
  }

  &-block {
    display: flex;
    align-items: flex-end;
    gap: 27px;
    padding-bottom: 10px;
  }

  & .org {
    margin-top: 8px;

    &-contact {
      margin-bottom: 8px;
      display: inline-flex;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 8px;
    }

    &-name {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 8px;
  }


  @media (max-width: 920px) {
    margin-left: 0;
    flex-direction: column;
    align-items: start;
    margin-top: 30px;
    .org-info-block {
      align-items: center;
      justify-content: center;
    }
    .org-block {
      order: 3;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .org-info-line {
      width: 100%;
      padding-top: 24px;
      border-top: 1px solid var(--color-separator);
      align-items: center;
    }
    .org-info-bottom-line {
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--color-separator);
    }
  }
  @media (max-width: 500px) {
    .org-info-line {
      flex-direction: column;
    }
    .org-info-block {
      justify-content: start;
      align-items: start;
    }
    .org-block {
      align-items: start;
    }
  }
}

</style>
