
export const blogRoutes = [
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "home" */ '@/views/blog/Blog.vue'),
    meta: { public: true }
  },
  {
    path: '/blog/:slug',
    name: 'blog-post',
    component: () => import(/* webpackChunkName: "home" */ '@/views/blog/BlogPost.vue'),
    meta: { public: true }
  }
]
