
export function checkNotifications () {
  return apiFetch('/notifications/count')
}

export function getNotifications (page = 1, perPage = 10) {
  return apiFetch(`/notifications/list`, { params: { page, per_page: perPage } })
}

export function readNotification (id) {
  return apiFetch(`/notifications/${id}/read`, { method: 'PATCH' })
}

export function readAllNotifications () {
  return apiFetch('/notifications/read-all', { method: 'PATCH' })
}

export function acceptNotification (id) {
  return apiFetch(`/notifications/${id}/accept`, { method: 'PATCH' })
}

export function declineNotification (id) {
  return apiFetch(`/notifications/${id}/decline`, { method: 'PATCH' })
}
