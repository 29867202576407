export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"id":"metrika","innerHTML":"(function(m,e,t,r,i,k,a){\n    m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n    m[i].l=1*new Date();\n    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n    (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n    ym(\"94127133\", \"init\", {\"defer\":true,\"clickmap\":true,\"trackLinks\":true,\"accurateTrackBounce\":true,\"webvisor\":true,\"ecommerce\":true});"},"(function(w, d, s, o){\n  var j = d.createElement(s); j.async = true; j.src = '//script.marquiz.ru/v2.js';j.onload = function() {\n    if (document.readyState !== 'loading') Marquiz.init(o);\n    else document.addEventListener(\"DOMContentLoaded\", function() {\n      Marquiz.init(o);\n    });\n  };\n  d.head.insertBefore(j, d.head.firstElementChild);\n})(window, document, 'script', {\n    host: '//quiz.marquiz.ru',\n    region: 'eu',\n    id: '6672d7df09c807002689475d',\n    autoOpen: 10,\n    autoOpenFreq: 'once',\n    openOnExit: false,\n    disableOnMobile: false\n  }\n);\n",{"src":"","body":true,"children":"(function(t, p) {window.Marquiz ? Marquiz.add([t, p]) : document.addEventListener('marquizLoaded', function() {Marquiz.add([t, p])})})('Widget', {id: '66c497f68d049500269d24b7', position: 'right', delay: 10, autoOpen: 10})"}],"noscript":[{"innerHTML":"<div><img src=\"https://mc.yandex.ru/watch/94127133\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div>"}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null