<template>
  <ClientOnly>
    <Popper v-bind="$attrs">
      <slot />
      <template #content="slot">
        <slot name="content" v-bind="slot" />
      </template>
    </Popper>
    <template #fallback>
      <slot />
    </template>
  </ClientOnly>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import Popper from 'vue3-popper'
</script>

<style scoped lang="scss">

</style>
