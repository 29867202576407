import { routes } from '~/routes'
import { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (_routes) => routes,
  scrollBehavior (to: any, from: any, savedPosition: any) {
    const nuxtApp = useNuxtApp()
    let position = savedPosition || void 0
    if (!position && from && to && to.meta.scrollToTop !== false && _isDifferentRoute(from, to)) {
      position = { left: 0, top: 0 }
    }
    if (to.path === from.path) {
      if (from.hash && !to.hash) {
        return { left: 0, top: 0 }
      }
      if (to.hash) {
        return { top: _getHashElementScrollMarginTop(to.hash) }
      }
    }
    const hasTransition = (route: any) => !!route.meta.pageTransition
    const hookToWait = hasTransition(from) && hasTransition(to) ? 'page:transition:finish' : 'page:finish'
    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce(hookToWait, async () => {
        await nextTick()
        if (to.hash) {
          position = { top: _getHashElementScrollMarginTop(to.hash) }
        }
        resolve(position)
      })
    })
  }
}

function _getHashElementScrollMarginTop (selector: any) {
  try {
    const elem = document.querySelector(selector)
    if (elem) {
      return parseFloat(getComputedStyle(elem).scrollMarginTop)
    }
  } catch {
  }
  return 0
}

function _isDifferentRoute (a: any, b: any) {
  const samePageComponent = a.matched[0] === b.matched[0]
  if (!samePageComponent) {
    return true
  }
  return samePageComponent && JSON.stringify(a.params) !== JSON.stringify(b.params)
}
