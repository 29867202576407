export const mentoringRoutes = [
  {
    path: '/mentors/:slug?',
    name: 'mentor-list',
    component: () => import(/* webpackChunkName: "mentoring-list" */ '@/views/mentoring/MentoringList'),
    meta: { public: true }
  },
  {
    path: '/mentors/:id(\\d+)',
    name: 'mentor-profile',
    component: () => import(/* webpackChunkName: "mentoring-profile" */ '@/views/user/UserProfile'),
    meta: { public: true }
  },
  {
    path: '/calendar',
    name: 'mentor-calendar',
    component: () => import(/* webpackChunkName: "mentoring-calendar" */ '@/views/mentoring/MentoringCalendar')
  },
  {
    path: '/mentoring/my',
    name: 'mentor-my',
    component: () => import(/* webpackChunkName: "mentoring-my" */ '@/views/mentoring/MentoringMy')
  },
  {
    path: '/mentoring/:id',
    name: 'mentor-lessons',
    component: () => import(/* webpackChunkName: "mentoring-lessons" */ '@/views/mentoring/MentoringLessons')
  },
  {
    path: '/mentoring/:id/meet/:meet',
    name: 'mentor-meet',
    component: () => import(/* webpackChunkName: "mentoring-meet" */ '@/views/mentoring/JitsiMeet')
  },
  {
    path: '/mentoring/:id/record/:meet',
    name: 'mentor-record',
    component: () => import(/* webpackChunkName: "mentoring-record" */ '@/views/mentoring/JitsiRecord')
  },
  {
    path: '/mentoring/:id/book',
    name: 'mentor-book',
    component: () => import(/* webpackChunkName: "mentoring-book" */ '@/views/mentoring/MentorBook')
  },
  {
    path: '/mentoring/:id/materials',
    name: 'mentor-materials',
    component: () => import(/* webpackChunkName: "mentoring-my-lessons" */ '@/views/mentoring/MentoringMyLessons'),
    // meta: { mentor: false },
    children: [
      {
        path: 'lessons/:lessonId',
        name: 'mentor-materials-lesson',
        component: () => import(/* webpackChunkName: "mentoring-my-lessons-view" */ '@/views/mentoring/MentoringMyLessonsView')
      }
    ]
  }
].map(route => ({
  ...route,
  meta: { ...route.meta }
}))
