<template>
  <NuxtLoadingIndicator />
  <MobileHeader />
  <Header />
  <div class="dnc-container dnc-darkbg" :class="{...$route.meta.container, ...($route.meta.soon ? { fluid: true, centered: true } : {})}">
    <ComingSoon v-if="config.public.env !== 'development' && $route.meta.soon" />
    <NuxtPage v-else />
  </div>
  <Footer />
  <MobileNavbar />
  <GoToTop />
</template>

<script setup>
import Header from '@/components/parts/Header'
import useAuth from '~/composables/useAuth'
import '@/assets/css/general.scss'
import Footer from '@/components/parts/Footer'
import useTheme from '~/composables/useTheme'
import MobileHeader from '@/components/parts/MobileHeader'
import ComingSoon from '@/components/parts/ComingSoon'

useHead({
  titleTemplate: title => title ? `${title} — Mentoring.digital` : 'Сервис менторинга Mentoring.digital - Поиск ментора и IT-наставников'
})

const config = useRuntimeConfig()

const theme = useTheme()
provide('theme', theme)

const { user, isAuthorized, updateUserInfo, setToken, logout } = await useAuth()
provide('user', user)
provide('isAuthorized', readonly(isAuthorized))
provide('updateUserInfo', updateUserInfo)
provide('setToken', setToken)
provide('logout', logout)

const route = useRoute()
const referral = useLocalStorage('referral', null)
watch(() => route.query?.referral, value => value && (referral.value = value), { immediate: true })
onMounted(() => {
  watch(() => route.name, () => localStorage.removeItem('confirm-page-delay'), { immediate: true })
})
</script>

<style lang="scss">

</style>
