<template>
  <div>
    <div :class="[ 'notify-icon', notifyCount>0 ? 'haveNew' : 'noNew' ]">
      <div :class="{ 'bell': notifyCount>0 }">
        <IconBell class="icon" />
      </div>
      <span v-if="notifyCount > 0" class="badge">{{ notifyText }}</span>
    </div>
  </div>
</template>

<script setup>
import { IconBell } from '@tabler/icons-vue'

const props = defineProps(['notifyCount'])

const notifyText = computed(() => {
  return props.notifyCount < 100 ? props.notifyCount : '99+'
})
</script>

<style lang="scss" scoped>
.notify-icon {
  width: 24px;
  height: 24px;
  position: relative;
}
.bell {
  animation: bellAnim 4s linear 0s infinite normal none;
}
.noNew {
  color: var(--color-elements-secondary);
}
.haveNew {
  color: var(--color-elements-primary);
}
.notify-icon .badge {
  position: absolute;
  top: -11px;
  right: -7px;
  padding: 0px 6px;
  line-height: 1.1rem;
  border-radius: 50%;
  font-size: 0.8rem;
  background: var(--color-primary);
  color: var(--color-text);
}

@keyframes bellAnim {
  0%, 30%, 100% { transform: translateX(0%); transform-origin: 50% 50%; }
  5% { transform: translateX(-5px) rotate(15deg); }
  10% { transform: translateX(4px) rotate(-15deg); }
  15% { transform: translateX(-4px) rotate(6deg); }
  20% { transform: translateX(2px) rotate(-3deg); }
  25% { transform: translateX(-1px) rotate(1deg); }
}
</style>
