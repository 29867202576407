<template>
  <Teleport to="body">
    <Transition name="mobile-popper">
      <div v-if="shown" class="mobile-popper" @click.self="close">
        <div class="mobile-popper__content" v-bind="$attrs">
          <div v-if="$slots.header" class="mobile-popper__header">
            <div class="mobile-popper__header-content"><slot name="header" /></div>
            <button class="mobile-popper__header-close" @click="close"><IconX /></button>
          </div>
          <div class="mobile-popper__body" @click="emit('bodyClick')"><slot /></div>
          <div v-if="$slots.footer" class="mobile-popper__footer"><slot name="footer" /></div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import { IconX } from '@tabler/icons-vue'

const props = defineProps({
  shown: Boolean
})
const emit = defineEmits(['close', 'bodyClick'])

const close = () => emit('close')

useModal(props)
</script>

<style scoped lang="scss">
.mobile-popper {
  position: fixed;
  inset: 0;
  z-index: 99999999;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  &__content {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: var(--color-bg);
    border-radius: 16px 16px 0 0;
    width: 100%;
  }
  &__header, &__footer {
    position: sticky;
    background-color: var(--color-bg);
    z-index: 20;
  }
  &__header {
    top: 0;
    border-bottom: 1px solid var(--color-separator);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header-content {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__header-close {
    width: 24px;
    height: 24px;
    color: var(--color-elements-tertiary);
  }
  &__footer {
    bottom: 0;
    border-top: 1px solid var(--color-separator);
    padding: 8px 15px;
    :deep(.base-button) {
      height: 46px;
    }
  }
}

.mobile-popper-enter-active, .mobile-popper-leave-active {
  transition: .3s background-color;
  .mobile-popper__content {
    transition: .3s transform;
  }
}
.mobile-popper-enter-from, .mobile-popper-leave-to {
  background: rgba(0, 0, 0, 0);
  .mobile-popper__content {
    transform: translateY(100%);
  }
}
</style>
