export const materialsRoutes = [
  {
    path: '/materials',
    name: 'materials',
    component: () => import(/* webpackChunkName: "materials-manage" */ '@/views/materials/MaterialsManage')
  },
  {
    path: '/materials/manage/sections/:sectionId?',
    name: 'section-edit',
    component: () => import(/* webpackChunkName: "section-edit" */ '@/views/materials/SectionEdit')
  },
  {
    path: '/materials/manage/sections/:sectionId/lessons/:lessonId?',
    name: 'lesson-edit',
    component: () => import(/* webpackChunkName: "lesson-edit" */ '@/views/materials/LessonEdit')
  },
  {
    path: '/materials/manage/sections/:sectionId/tests/:testId?',
    name: 'test-edit',
    component: () => import(/* webpackChunkName: "test-edit" */ '@/views/materials/TestEdit')
  },
  {
    path: '/materials/manage/sections/:sectionId/homeworks/:lessonId?',
    name: 'homework-edit',
    component: () => import(/* webpackChunkName: "homework-edit" */ '@/views/materials/HomeworkEdit')
  }
].map(route => ({
  ...route,
  meta: { ...route.meta, mentor: true }
}))
