export const paymentRoutes = [
  {
    path: '/pay/success',
    name: 'pay-success',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/PaymentSuccessful')
  },
  {
    path: '/pay/error',
    name: 'pay-error',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/PaymentFailed')
  },
  {
    path: '/pay/card/success',
    name: 'pay-card-success',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/PaymentCardSuccessful')
  },
  {
    path: '/pay/card/fail',
    name: 'pay-card-error',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/PaymentCardFailed')
  },
  {
    path: '/pay',
    name: 'pay-form',
    component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/Payment'),
    meta: { container: { fluid: true } }
  }
]
