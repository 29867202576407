<template>
  <div class="navbar">
    <div class="navbar-items">
      <slot v-if="$slots.default" />
      <div v-else></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.navbar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  background: var(--color-bg);
  border-top: 1px solid var(--color-separator);
  z-index: 9999;
  padding: 0 16px;
  gap: 10px;
  @media (min-width: 921px) {
    display: none;
  }
  .navbar-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
