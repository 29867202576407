
function _ (name, maxAge = 3600 * 24 * 365) {
  const config = useRuntimeConfig()
  return useStatefulCookie(name, {
    path: '/',
    maxAge,
    sameSite: 'lax',
    secure: config.public.env === 'production'
  })
}

export function useTokenCookie (...args) {
  return _('token', ...args)
}
export function useThemeCookie (...args) {
  return _('theme', ...args)
}
