import { toFormData } from '@/utils/request'
import { prepareProfileData } from '@/api/helpers'
import cloneDeep from 'lodash.clonedeep'

export function login (data) {
  return apiFetch('/auth/login', { method: 'POST', body: data })
}

export function register (data) {
  data = cloneDeep(data)
  prepareProfileData(data)
  return apiFetch('/auth/register', { method: 'POST', body: toFormData(data) })
}

export function registerValidation (data) {
  data = cloneDeep(data)
  prepareProfileData(data)
  return apiFetch('/auth/register-validation', { method: 'POST', body: toFormData(data) })
}

export function confirm (data) {
  return apiFetch('/auth/confirm', { method: 'POST', body: data })
}

export function logout () {
  return apiFetch('/auth/logout', { method: 'DELETE' })
}

export function resendEmail (data) {
  return apiFetch('/auth/resend-email', { method: 'POST', body: data })
}

export function passwordRestoreRequest (data) {
  return apiFetch('/auth/password-restore-request', { method: 'POST', body: data })
}

export function passwordRestore (data) {
  return apiFetch('/auth/password-restore', { method: 'POST', body: data })
}

export function changePassword (data) {
  return apiFetch('/auth/change-password', { method: 'POST', body: data })
}
