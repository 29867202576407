export const projectRoutes = [
  {
    path: '/projects',
    name: 'project-list',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/project/ProjectList'),
    meta: { public: true }
  },
  {
    path: '/projects/my',
    name: 'project-my',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/project/ProjectMy'),
  },
  {
    path: '/projects/create',
    name: 'project-create',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/project/ProjectEdit'),
    meta: { mobile: { header: false, footer: false, title: 'Создать проект' } }
  },
  {
    path: '/projects/:id',
    name: 'project-single',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/project/ProjectView'),
    children: [
      {
        path: '',
        name: 'project-view',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/project/ProjectDescription'),
        meta: { public: true }
      },
      {
        path: 'responses',
        name: 'project-responses',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/project/ProjectResponses')
      }
    ]
  },
  {
    path: '/projects/:id/edit',
    name: 'project-edit',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/project/ProjectEdit'),
    meta: { mobile: { header: false, footer: false, title: 'Изменить проект' } }
  },
  {
    path: '/specialists/:slug?',
    name: 'specialist-list',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/project/SpecialistList'),
    meta: { public: true }
  },
  {
    path: '/specialists/:id(\\d+)',
    name: 'specialist-profile',
    component: () => import(/* webpackChunkName: "mentoring-profile" */ '@/views/user/UserProfile'),
    meta: { public: true }
  },
]
