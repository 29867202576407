import { toFormData } from '@/utils/request'
import cloneDeep from 'lodash.clonedeep'
import { prepareDatetimes, prepareProfileData, prepareProfileEducations } from '@/api/helpers'

export function getUserInfo () {
  return apiFetch('/user')
}

export function storeInfo (data) {
  return apiFetch('/user/info', { method: 'POST', body: data })
}

export function storeCredentials (data) {
  return apiFetch('/user/credentials', { method: 'PATCH', body: data })
}

export function storeCity (data) {
  return apiFetch('/user/city', { method: 'PATCH', body: data })
}

export function storeContact (data) {
  return apiFetch('/user/contact', { method: 'PATCH', body: data })
}

export function updateEmail (data) {
  return apiFetch('/user/email', { method: 'PATCH', body: data })
}

export function cancelUpdateEmail () {
  return apiFetch('/user/email', { method: 'DELETE' })
}

export function storeGeneralInfo (data) {
  data = cloneDeep(data)
  prepareProfileData(data)
  return apiFetch('/user/general', { method: 'PATCH', body: data })
}

export function storeMentorInfo (data) {
  data = cloneDeep(data)
  prepareProfileData(data)
  return apiFetch('/user/mentor', { method: 'POST', body: data })
}

export function storeSpecialistInfo (data) {
  data = cloneDeep(data)
  prepareProfileData(data)
  return apiFetch('/user/specialist', { method: 'PATCH', body: data })
}

export function storeMainInfo (data) {
  return apiFetch('/user/main', { method: 'POST', body: data })
}

export function storePersonalInfo (data) {
  return apiFetch('/user/personal', { method: 'POST', body: data })
}

export function storeAboutInfo (data) {
  return apiFetch('/user/about', { method: 'POST', body: data })
}

export function storeSocialInfo (data) {
  return apiFetch('/user/social-links', { method: 'POST', body: data })
}

export function storeEducationInfo (data) {
  data = cloneDeep(data)
  prepareProfileEducations(data)
  return apiFetch('/user/education', { method: 'POST', body: data })
}

export function storeEducationAdditionalInfo (data) {
  data = cloneDeep(data)
  prepareProfileData(data)
  return apiFetch('/user/education-additional', { method: 'POST', body: data })
}

export async function storeAllEducationInfo (data) {
  // const [education, additional] = await Promise.all([
  //   storeEducationInfo(data),
  //   storeEducationAdditionalInfo(data)
  // ])
  //
  // return {
  //   data: { ...(education.data ?? {}), ...(additional.data ?? {}) },
  //   errors: { ...(education.errors ?? {}), ...(additional.errors ?? {}) },
  //   hasError: education.hasError || additional.hasError
  // }
  return { data: {} }
}

export function storeEventsInfo (data) {
  return apiFetch('/user/events', { method: 'POST', body: data })
}

export function storeExperienceInfo (data) {
  data = cloneDeep(data)
  prepareProfileData(data)
  return apiFetch('/user/experience', { method: 'POST', body: data })
}

export function storeSpecializationsInfo (data) {
  return apiFetch('/user/specializations', { method: 'POST', body: data })
}

export function storeInterestsInfo (data) {
  return apiFetch('/user/interests', { method: 'POST', body: data })
}

export function storeInterestsSetupInfo (data) {
  return apiFetch('/user/interests-setup', { method: 'POST', body: data })
}

export function storeUserSettings (data) {
  return apiFetch('/user/settings', { method: 'POST', body: data })
}

export function storeAvatar (data) {
  return apiFetch('/user/upload/avatar', { method: 'POST', body: toFormData(data) })
}

export function storeFile (file, onUploadProgress = () => {}, isImage = false) {
  return apiFetch(isImage ? '/materials/image' : '/user/upload', { method: 'POST', body: [File, Blob].some(T => file instanceof T) ? toFormData({ file }) : file })
}

export function loadDocuments (token, body, onUploadProgress) {
  return apiFetch('/user/document', { method: 'POST', body, onUploadProgress })
}

export function getProfile (id) {
  return apiFetch(`/profiles/${id}`).then(prepareDatetimes)
}

export function storeMentorPrice (data) {
  return apiFetch('/mentor/price', { method: 'PATCH', body: data })
}

export function storeMentorVisible (data) {
  return apiFetch('/mentor/visible', { method: 'PATCH', body: data })
}

export function storeSpecialistVisible (data) {
  return apiFetch('/user/specialist/visible', { method: 'PATCH', body: data })
}

export function getLanguages () {
  return apiFetch('/languages')
}
