<template>
  <PopperMenu offset-distance="24" placement="bottom-end" :disabled="!user">
    <button class="user-control user-control--main" v-if="user">
      <img v-if="user.personal?.avatar_url" :src="user.personal?.avatar_url" class="user-control__avatar" :alt="user.personal?.first_name">
      <AvatarPlaceholder v-else light :data="user.personal" class="user-control__avatar" />
      <span class="user-control__name">{{ user.personal?.first_name }}</span>
      <IconChevronDown class="icon user-control__chevron" />
    </button>
    <NuxtLink v-else :to="{ name: 'auth-login' }" class="login-btn"><IconLogin class="icon" /></NuxtLink>

    <template v-if="user" #header>
      <div class="user-control">
        <img v-if="user.personal?.avatar_url" :src="user.personal?.avatar_url" class="user-control__avatar" :alt="user.personal?.first_name">
        <AvatarPlaceholder v-else light :data="user.personal" class="user-control__avatar" />
        <span class="user-control__name">{{ user.personal?.first_name }}</span>
      </div>
    </template>
    <template v-if="user" #content>
      <PopperMenuItem :to="myProfileRoute"><IconUser class="icon" />Мой профиль</PopperMenuItem>
      <PopperMenuItem class="desktop-only" :to="{ name: 'mentor-my' }"><IconSchool class="icon" />{{ user?.is_mentor ? 'Менти' : 'Мои менторы' }}</PopperMenuItem>
      <PopperMenuItem v-if="user?.is_mentor" class="desktop-only" :to="{ name: 'materials' }"><IconFileText class="icon" />Материалы</PopperMenuItem>
      <PopperMenuItem :to="{ name: 'project-my' }"><IconBriefcase class="icon" />Мои проекты</PopperMenuItem>
      <PopperMenuItem :to="{ name: 'user-settings' }"><IconSettings class="icon" />Настройки</PopperMenuItem>
      <PopperMenuItem @click="logout"><IconLogout class="icon" />Выйти</PopperMenuItem>
    </template>
  </PopperMenu>
</template>

<script setup>
import {
  IconChevronDown,
  IconUser,
  IconSettings,
  IconLogout,
  IconLogin,
  IconSchool,
  IconFileText,
  IconBriefcase
} from '@tabler/icons-vue'
import PopperMenu from '@/components/common/PopperMenu'
import PopperMenuItem from '@/components/common/PopperMenuItem'
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder.vue'

const user = inject('user')
const logout = inject('logout')

const myProfileRoute = useMyProfileRoute()
</script>

<style scoped lang="scss">
.user-control {
  display: flex;
  align-items: center;
  gap: 10px;
  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
    @media (max-width: 920px) {
      width: 32px;
      height: 32px;
    }
  }
  &__name {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-elements-primary);
    transition: .2s color;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 166px;
    flex-shrink: 0;
  }
  &__chevron {
    width: 24px;
    height: 24px;
    color: var(--color-elements-tertiary);
    flex-shrink: 0;
  }
  &--main {
    .user-control {
      &__name, &__chevron {
        @media (max-width: 920px) {
          display: none;
        }
      }
    }
    &:hover .user-control__name {
      color: var(--color-primary);
    }
  }
}

.login-btn {
  color: var(--color-elements-secondary);
  display: block;
  .icon {
    width: 28px;
    height: 28px;
    transform: rotate(180deg);
  }
}

.desktop-only {
  @media (max-width: 920px) {
    display: none;
  }
}
</style>
