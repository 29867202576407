
export function createNotFoundError () {
  return createError({ statusCode: 404, statusMessage: 'Страница не найдена', fatal: true })
}

export function createUnauthenticatedError () {
  return createError({ statusCode: 401, statusMessage: 'Сессия более не действительна', fatal: true })
}

export function createForbiddenError () {
  return createError({ statusCode: 403, statusMessage: 'Доступ к данной странице запрещён', fatal: true })
}

export function createBlacklistedError () {
  return createError({ statusCode: 403, statusMessage: 'Ваш аккаунт заблокирован администрацией сайта', fatal: true })
}

export function createInternalError () {
  return createError({ statusCode: 500, statusMessage: 'Что-то пошло не так. Попробуйте позже.', fatal: true })
}
