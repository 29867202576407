import { deepMap } from '@/utils/deepMap'

function isIsoDate (str) {
  if (!/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(str)) return false
  const d = new Date(str)
  return d instanceof Date && !isNaN(d.getTime())
}
export function prepareDatetimes (data) {
  return deepMap(data, value => isIsoDate(value) ? new Date(value) : value)
}

export function prepareProfileEducations (data) {
  if (data.educations) {
    data.educations = data.educations.filter(edu => {
      return edu.organization || edu.direction || edu.year_to
    })
    data.educations.forEach(e => {
      e.still_studying = !!e.still_studying
      if (!e.year_to || e.still_studying) delete e.year_to
    })
  }
  return data
}

export function prepareProfileProjects (data) {
  if (data.projects) {
    data.projects = data.projects.filter(a => ['name', 'date_from', 'date_to', 'position', 'description', 'link'].some(b => !!a[b]))
    data.projects.forEach(e => {
      e.still_working = !!e.still_working
      if (!e.date_to || e.still_working) delete e.date_to
    })
  }
  return data
}

export function prepareProfileSkills (data, key) {
  data[key] = data[key].filter(s => s.skill_id || s.skill_name)
  return data
}

export function prepareProfileRequests (data, key) {
  data[key] = data[key].split('\n').filter(r => r.trim().length)
  return data
}

export function prepareProfileData (data) {
  if (data.contacts && typeof data.contacts === 'object') data.contacts = Object.values(data.contacts).some(v => !!v) ? data.contacts : null
  ;['mentor_skills', 'skills', 'specialist_skills'].forEach(k => data[k] && prepareProfileSkills(data, k))
  ;['mentor_requests', 'requests', 'specialist_requests'].forEach(k => data[k] && typeof data[k] === 'string' && prepareProfileRequests(data, k))
  if (data.languages) data.languages = Array.from(data.languages)
  if (data.events) data.events = data.events.filter(a => ['name', 'date_from', 'role'].some(b => !!a[b]))
  if (data.educations) prepareProfileEducations(data)
  if (data.projects) prepareProfileProjects(data)
  return data
}
