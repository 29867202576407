<template>
  <Navbar class="navbar" :class="{'mobile-hidden': $route.meta.mobile?.header === false}">
    <template v-if="!user">
      <NavbarItem :to="{ name: 'specialist-list' }"><IconUsers class="icon" />Специалисты</NavbarItem>
      <NavbarItem :to="{ name: 'project-list' }"><IconListSearch class="icon" />Проекты</NavbarItem>
      <NavbarItem :to="{ name: 'mentor-list' }"><IconSchool class="icon" />Менторы</NavbarItem>
    </template>
    <template v-else>
      <NuxtLink :to="{name: 'pay-form'}" class="pay-link">
        <CoinIndicator add-button />
      </NuxtLink>
      <NavbarItem v-if="user?.is_mentor" :to="{ name: 'materials' }"><IconFileText class="icon" />Материалы</NavbarItem>
      <NavbarItem :to="{ name: 'mentor-calendar' }"><IconCalendar class="icon" />Календарь</NavbarItem>
      <NavbarItem :to="{ name: 'mentor-my' }"><IconSchool class="icon" />{{ user?.is_mentor ? 'Менти' : 'Мои менторы' }}</NavbarItem>
    </template>
    <PopperMenu drawer>
      <NavbarItem><IconMenu2 class="icon" />Меню</NavbarItem>
      <template #content>
        <PopperMenuItem :to="{ name: 'mentor-list' }">Менторы</PopperMenuItem>
        <PopperMenuItem :to="{ name: 'project-list' }">Проекты</PopperMenuItem>
        <PopperMenuItem :to="{ name: 'specialist-list' }">Специалисты</PopperMenuItem>
        <PopperMenuItem :to="{ name: 'blog' }">Блог</PopperMenuItem>
        <PopperMenuItem :to="{ name: 'business' }">Для бизнеса</PopperMenuItem>
      </template>
    </PopperMenu>
  </Navbar>
</template>

<script setup>
import { IconUsers, IconListSearch, IconSchool, IconMenu2, IconCalendar, IconFileText } from '@tabler/icons-vue'
import CoinIndicator from "~/components/parts/CoinIndicator.vue";

const user = inject('user')
</script>

<style scoped lang="scss">
.navbar {
  position: sticky !important;
  bottom: 0 !important;
  :deep(.navbar-items) {
    max-width: 400px;
  }
  .pay-link:hover {
    opacity: 1;
  }
}
</style>
