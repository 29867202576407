
const grayPageRoutes = ['user-info', 'expert-info', 'school-landing']
const grayHeaderRoutes = ['user-info', 'expert-info', /school-.*/]

export default function useGrayRoute () {
  const route = useRoute()
  const isPageGray = computed(() => route.meta.grayRoute || grayPageRoutes.some(condition =>
    condition instanceof RegExp ? condition.test(route.name) : condition === route.name))
  const isHeaderGray = computed(() => route.meta.grayRoute || grayHeaderRoutes.some(condition =>
    condition instanceof RegExp ? condition.test(route.name) : condition === route.name))
  return { isPageGray, isHeaderGray }
}
