
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.name === 'auth-token') {
    if (to.query?.token) useTokenCookie().value = to.query?.token
    return navigateTo({ name: 'home' })
  }

  if (to.meta.public) return

  const { user } = await useAuth()

  if (!user.value) {
    return navigateTo({
      name: 'auth-login',
      query: { redirect: to.fullPath }
    })
  } else if (
    !user.value ||
    (to.meta.requiresRole && user.value.role !== to.meta.requiresRole) ||
    (to.meta.mentor !== undefined && !!user.value.is_mentor !== !!to.meta.mentor)
  ) {
    throw createForbiddenError()
  }
})
