<template>
  <header class="evq-navbar_container header" :class="{'dnc-darkbg': !isHeaderGray, 'mobile-hidden': $route.meta.mobile?.header === false}">
    <div class="evq-navbar">
      <div class="evq-navbar__logo-wrap">
        <NuxtLink :to="{ name: 'home' }" class="evq-navbar_logo" ><Logo class="logo" /></NuxtLink>
        <div v-if="user?.role === 'admin'">Личный кабинет</div>
      </div>
      <nav class="evq-navbar_menu" v-if="!user || user.role !== 'admin'">
        <ul class="evq_menu">
          <li class="evq-menu-item">
            <NuxtLink :to="{ name: 'mentor-list' }" class="evq-menu-link">Менторы</NuxtLink>
          </li>
          <li class="evq-menu-item">
            <NuxtLink :to="{ name: 'project-list' }" class="evq-menu-link">Проекты</NuxtLink>
          </li>
          <li class="evq-menu-item">
            <NuxtLink :to="{ name: 'specialist-list' }" class="evq-menu-link">Специалисты</NuxtLink>
          </li>
          <li class="evq-menu-item">
            <NuxtLink :to="{ name: 'business' }" class="evq-menu-link">Для бизнеса</NuxtLink>
          </li>
          <li class="evq-menu-item">
            <NuxtLink :to="{ name: 'blog' }" class="evq-menu-link">Блог</NuxtLink>
          </li>
          <li class="evq-menu-item" v-if="user && user.upcoming_lesson">
            <NuxtLink class="evq-menu-link"
                        style="color: var(--color-primary)"
                        :to="{ name: 'mentor-lessons', params: { id: !user.is_mentor ? user.upcoming_lesson.mentor_id : user.upcoming_lesson.menti_id } }">
              Занятие в {{ formatTime(new Date(user.upcoming_lesson.datetime_start)) }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <div class="evq-navbar-profile flexbox">
        <ThemeSwitcher/>
        <NuxtLink v-if="user" :to="{name: 'mentor-calendar'}" class="icon-menu-item" title="Календарь">
          <IconCalendar class="icon" />
        </NuxtLink>
        <NuxtLink :to="{name: 'user-notifications'}" class="icon-menu-item" title="Уведомления">
          <NotificationIndicator v-if="user" :notify-count="notifyCount" />
        </NuxtLink>
        <template v-if="!user">
          <NuxtLink class="evq-menu-link" :to="{name: 'auth-login'}">Вход</NuxtLink>
        </template>
        <template v-else>
          <NuxtLink :to="{name: 'pay-form'}">
            <CoinIndicator add-button />
          </NuxtLink>
          <UserControl />
        </template>
      </div>
    </div>
  </header>
  <div class="evq-mobile_menu" :class="{ 'evq-mobile_menu--active': mobileToggle, 'mobile-hidden': $route.meta.mobile?.header === false }">
    <div class="header__top">
      <NuxtLink :to="{name: 'home'}" class="evq-navbar_logo">
        <Logo class="logo" />
      </NuxtLink>
      <ThemeSwitcher />
      <NuxtLink v-if="user" :to="{name: 'user-notifications'}">
        <NotificationIndicator :notify-count="notifyCount" />
      </NuxtLink>
      <UserControl />
    </div>
  </div>
</template>

<script setup>
import Logo from '@/components/parts/Logo'
import ThemeSwitcher from '@/components/parts/ThemeSwitcher'
import NotificationIndicator from '@/components/parts/NotificationIndicator'
import useGrayRoute from '~/composables/useGrayRoute'
import { checkNotifications } from '@/api/notifications'
import CoinIndicator from '@/components/parts/CoinIndicator'
import { formatTime } from '~/utils/date'
import UserControl from '@/components/user/UserControl'
import { IconCalendar } from '@tabler/icons-vue'

const user = inject('user')
const isAuthorized = inject('isAuthorized')
const logout = inject('logout')
const { isHeaderGray } = useGrayRoute()
const mobileToggle = ref(false)
const notifyCount = ref(0)

const updateNotifications = async () => {
  if (user.value?.id > 0) {
    const notifyResponse = await checkNotifications()
    notifyCount.value = notifyResponse.count
  }
}

useIntervalFn(updateNotifications, 10000, { immediateCallback: true })
watchImmediate(isAuthorized, auth => auth && updateNotifications())
</script>

<style lang="scss" scoped>
.header_avatar {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 44px;
  background: var(--color-secondary);
}

.evq-navbar-profile_avatar {
  height: 44px;
  width: 44px;
  text-align: center;
  color: var(--color-bg) !important;
}
.evq-navbar-profile_exit {
  font-size: 20px;
  cursor: pointer;
}

@media (min-width: 920px) {
  .notifications-flyout-wrap {
    position: relative;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(0.5em);
    opacity: 0;
  }
}

.user-info-link {
  display: flex;
  align-items: center;
}

.evq-container {
  border-bottom: 2px solid var(--color-bg);
}

:deep(.user-control) {
  margin-left: 24px;
  @media (max-width: 920px) {
    margin-left: 0;
  }
}

.icon-menu-item {
  color: var(--color-elements-secondary);
  .icon {
    width: 24px;
    height: 24px;
  }
  &.router-link-active {
    opacity: .5;
  }
}

.flexbox {
  display: flex;
  align-items: center;
}

.flexbox > * + * {
  margin-left: 32px;
}

@media (min-width: 640px) {
  .flexbox > * + * {
    margin-left: 24px;
  }
}

.evq-navbar .logo {
  width: 197px;
  height: 45px;
  margin-right: 30px;
}
.evq-mobile_menu .logo {
  width: 128px;
  height: 32px;
}

.header__top {
  .evq-navbar_logo {
    margin-right: auto;
  }
}

.evq-navbar_logo:hover {
  opacity: 1;
}
</style>
