<template>
  <HybridRenderer v-slot="{ server }">
    <HybridPopper v-if="server || !isMobile" v-bind="$attrs" :disabled="disabled">
      <slot />
      <template #content="{ close }">
        <div class="popper-menu" @click="[close(), emit('select', model)]">
          <PopperMenuItem v-if="select" v-for="option in options" :key="option.id" @click="model = option.id" :active="option.id === model">{{ option.name }}</PopperMenuItem>
          <slot v-else name="content" />
        </div>
      </template>
    </HybridPopper>
    <template v-else>
      <div class="popper-menu-container" @click.stop="!disabled && (mobileShown = true)" v-bind="$attrs">
        <slot />
      </div>
      <MobilePopper
        class="mobile-popper-menu"
        :class="{ 'mobile-popper-menu--drawer': drawer }"
        :shown="mobileShown"
        @close="mobileShown = false"
        @body-click="onMobilePopperClick"
      >
        <template v-if="$slots.header || name" #header>
          <slot v-if="$slots.header" name="header" />
          <template v-else>{{ name }}</template>
        </template>
        <template #default>
          <PopperMenuItem v-if="select" v-for="option in options" :key="option.id" @click="preselect = option.id" :active="option.id === preselect" select>{{ option.name }}</PopperMenuItem>
          <slot v-else name="content" />
        </template>
        <template v-if="$slots.footer || drawer" #footer>
          <button v-if="drawer" class="drawer-close-btn" @click="mobileShown = false"><IconX class="icon" /></button>
          <slot v-else name="footer" :submit="submit" />
        </template>
      </MobilePopper>
    </template>
  </HybridRenderer>
</template>

<script setup>
import { IconX } from '@tabler/icons-vue'

const props = defineProps({
  select: Boolean,
  options: Array,
  modelValue: [String, Number],
  name: String,
  drawer: Boolean,
  disabled: Boolean
})
const emit = defineEmits(['select', 'update:modelValue'])

const { isMobile } = useBreakpoints()

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const preselect = ref(null)

const onMobilePopperClick = () => {
  if (!props.select) mobileShown.value = false
}

const submit = () => {
  model.value = preselect.value
  emit('select', model.value)
  mobileShown.value = false
}

const mobileShown = ref(false)

watchEffect(() => {
  if (!mobileShown.value) return
  preselect.value = model.value
})
</script>

<style lang="scss">
.mobile-popper-menu {
  .popper-menu-item {
    padding: 12px 15px;
    &:first-child {
      padding-top: 16px;
    }
    &:last-child {
      padding-bottom: 16px;
    }
  }
  .drawer-close-btn {
    margin: 0 auto 24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--color-primary);
    color: var(--color-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  &--drawer {
    padding-top: 16px;
    .popper-menu-item {
      justify-content: center;
    }
    .mobile-popper__footer {
      border-top: none;
    }
  }
}
</style>
