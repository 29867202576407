<template>
  <div v-if="plain" class="price" :class="{ 'price--inline': inline, 'price--small': small, 'discount': hasDiscount }">
    <div class="price__value">{{ coins ?? user.coins_total }}</div>
    <IconCurrencyRubel class="price__icon icon" />
  </div>
  <div v-else class="coin-indicator" :class="{ 'coin-indicator-inline': inline, 'discount': hasDiscount }">
    <IconPlus class="icon" v-if="addButton" />
    <span class="balance">{{ coins ?? user.coins_total }}</span>
    <IconCurrencyRubel class="icon" />
  </div>
</template>

<script setup>
import { IconPlus, IconCurrencyRubel } from '@tabler/icons-vue'

const props = defineProps({
  coins: Number,
  plain: Boolean,
  small: Boolean,
  hasDiscount: Boolean,
  addButton: {
    type: Boolean,
    default: false
  },
  inline: {
    type: Boolean,
    default: false
  }
})

const user = inject('user')
</script>

<style scoped lang="scss">
.coin-indicator {
  padding: 8px 10px;
  height: 40px;
  border-radius: 20px;
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  &.discount {
    background-color: var(--color-options-cart-pink);
  }

  &-inline {
    display: inline-flex;
    margin: 0 5px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 3px;
  &__value {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-bg-dark);
  }
  &__icon {
    width: 21px;
    height: 21px;
    color: var(--color-elements-tertiary);
    position: relative;
  }
  &--inline {
    display: inline-flex;
  }
  &--small {
    gap: 0;
    .price__value {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 1px;
    }
    .price__icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
