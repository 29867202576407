<template>
  <Transition name="go-up-slide-in">
    <button v-if="shown" class="go-to-top-btn" @click="onClick">
      <IconArrowUp class="icon" />
    </button>
  </Transition>
</template>

<script setup>
import { IconArrowUp } from '@tabler/icons-vue'

const { isMobile } = useBreakpoints()

const { height } = useWindowSize()
const { y } = useWindowScroll()

const shown = computed(() => !isMobile.value && y.value >= height.value)

function onClick () {
  document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<style scoped lang="scss">
.go-to-top-btn {
  width: 55px;
  height: 55px;
  border-radius: 8px;
  background: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 19px rgba(112, 148, 79, 0.42);
  color: var(--color-bg);
  position: fixed;
  bottom: 22px;
  right: 22px;
  z-index: 999;
  transition: .2s transform;
  &:hover {
    transform: translateY(-3px);
  }
  .icon {
    width: 23px;
    height: 23px;
  }
}

.go-up-slide-in-enter-active,
.go-up-slide-in-leave-active {
  transition: 0.4s ease;
  transition-property: transform, opacity;
}

.go-up-slide-in-enter-from,
.go-up-slide-in-leave-to {
  transform: translateY(8px);
  opacity: 0;
}
</style>
