<template>
  <div class="status-page-container">
    <div class="status-page">
      <div class="status-page__icon" v-if="$slots.icon"><slot name="icon" /></div>
      <h1 class="status-page__title" v-if="$slots.title"><slot name="title" /></h1>
      <div class="status-page__content" v-if="$slots.desc || $slots.hint">
        <div class="status-page__desc" v-if="$slots.desc"><slot name="desc" /></div>
        <div class="status-page__hint" v-if="$slots.hint"><slot name="hint" /></div>
      </div>
      <div class="status-page__actions" v-if="$slots.actions"><slot name="actions" /></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.status-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px 16px;
}

.status-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-elements-primary);
  gap: 24px;
  max-width: 502px;
  text-align: center;
  &__title {
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__hint {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-elements-secondary);
  }
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-elements-secondary);
  }
}
</style>
