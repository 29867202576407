export const authRoutes = [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Auth'),
    meta: { container: { fluid: true, centered: true }, mobile: { header: false, footer: false } },
    children: [
      {
        path: '',
        name: 'auth-login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/AuthLogin')
      },
      {
        path: 'register',
        name: 'auth-register-entry',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/AuthRegister')
      }
    ]
  },
  {
    path: '/auth/register/:role(menti|client)',
    name: 'auth-register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register'),
    meta: { container: { fluid: true, centered: true }, mobile: { header: false, footer: false, title: ({ params }) => ({ menti: 'Найти ментора', client: 'Разместить проект' })[params.role], backTo: { name: 'auth-login' } } }
  },
  {
    path: '/auth/register/:role(mentor|specialist)',
    name: 'auth-register-advanced',
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/RegisterAdvanced'),
    meta: { mobile: { header: false, footer: false, title: ({ params }) => `Стать ${{ mentor: 'ментором', specialist: 'специалистом' }[params.role]}`, backTo: { name: 'auth-login' } } }
  },
  {
    path: '/auth/confirm',
    name: 'auth-confirm',
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Confirm'),
    meta: { container: { fluid: true, centered: true }, mobile: { header: false, footer: false, title: 'Подтверждение E-mail' } }
  },
  {
    path: '/auth/confirmed',
    name: 'auth-confirmed',
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Confirmed'),
    meta: { container: { fluid: true, centered: true } }
  },
  {
    path: '/auth/restore',
    name: 'auth-restore',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/PasswordRestore'),
    meta: { container: { fluid: true, centered: true }, mobile: { header: false, footer: false, title: 'Забыли пароль', backTo: { name: 'auth-login' } } }
  },
  {
    path: '/auth/password-restore',
    name: 'auth-change',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/PasswordChange'),
    meta: { container: { fluid: true, centered: true }, mobile: { header: false, footer: false, title: 'Забыли пароль' } }
  },
  {
    path: '/auth/token',
    name: 'auth-token',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Token')
  },
].map(route => ({
  ...route,
  meta: { public: true, ...route.meta }
}))
