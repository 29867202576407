import { useTokenCookie } from '~/composables/cookie'
import useAuth from '~/composables/useAuth'

async function _ (f, request, opts) {
  const config = useRuntimeConfig()
  const token = useTokenCookie()
  const { removeToken } = await useAuth(false)

  const headers = {
    ...(opts?.headers ?? {}),
    Authorization: `Bearer ${token.value ?? ''}`,
    Accept: 'application/json'
  }

  return await f(request, {
    baseURL: config.public.baseURL,
    ...opts,
    headers,
    onResponseError: async function (error) {
      console.error(error)
      if (config.public.env !== 'development' && error.response.status === 500) showError(createInternalError())
      if (error.response.status === 401) {
        await removeToken()
        showError(createUnauthenticatedError())
      }
    }
  })
}

export default function useApiFetch (request, opts = {}) {
  return _(useFetch, request, opts)
}

export function apiFetch (request, opts = {}) {
  return _($fetch, request, opts)
}
