<template>
  <MobilePopupHeader
    v-if="$route.meta.mobile?.header === false"
    :title="title"
    :back="!!$route.meta.mobile?.backTo"
    @back="back"
    @close="close"
  />
</template>

<script setup>
import { useSafeGoBack } from '~/composables/router'

const router = useRouter()
const route = useRoute()

const title = computed(() => (title => typeof title === 'function' ? title(route) : title)(route.meta.mobile?.title))

function back () {
  if (!route.meta.mobile?.backTo) return
  router.replace(route.meta.mobile.backTo)
}
const close = useSafeGoBack()
</script>

<style scoped lang="scss">

</style>
