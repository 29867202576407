import { logout as logoutRequest } from '@/api/auth'
import { getUserInfo } from '@/api/user'
// import { getExpertProfile } from '@/api/expert'
import { useTokenCookie } from '~/composables/cookie'

function removeOldCookiesIfNeeded () {
  const cookie = name => useCookie(name, {
    path: '/',
    maxAge: 3600 * 24 * 365,
    domain: '.mentoring.digital',
    sameSite: 'lax',
    secure: true
  })
  const token = cookie('token')
  const theme = cookie('theme')
  if (token.value) token.value = null
  if (theme.value) theme.value = null
}

export default async function useAuth (immediateLoad = true) {
  const router = useRouter()
  const bugsnag = useBugsnag()

  removeOldCookiesIfNeeded()
  const token = useTokenCookie()
  const user = useState('user', () => null)
  watchImmediate(user, user => bugsnag.setUser(...(user ? [user.id, user.email] : [null])))

  async function updateUserInfo () {
    if (!token.value) return (user.value = null)

    // if (profile.role === 'expert') {
    //   const expertData = (await getExpertProfile()).data
    //   profile = {
    //     ...expertData,
    //     personal: {
    //       first_name: expertData.first_name,
    //       middle_name: expertData.middle_name,
    //       last_name: expertData.last_name,
    //       avatar_url: expertData.avatar?.url
    //     },
    //     registered: true
    //   }
    // }

    user.value = await getUserInfo()
  }

  async function setToken (newToken) {
    token.value = newToken
    await updateUserInfo()
  }

  async function removeToken () {
    const oldToken = token.value
    token.value = null
    if (oldToken) await router.push({ name: 'auth-login' })
    await updateUserInfo()
  }

  async function logout () {
    await logoutRequest()
    await removeToken()
  }

  const isAuthorized = computed(() => !!user.value)

  if (immediateLoad && !user.value) await updateUserInfo()

  return {
    user,
    isAuthorized,
    updateUserInfo,
    setToken,
    removeToken,
    logout
  }
}
