<template>
  <div class="avatar-placeholder" :class="{ small, light, medium }" :style="{backgroundColor: data?.avatar_color ?? avatarColor}">
    <template v-if="content">{{ content }}</template>
    <IconCameraPlus class="icon" v-else />
  </div>
</template>

<script setup>
import { IconCameraPlus } from '@tabler/icons-vue'

const props = defineProps({
  data: Object,
  firstName: String,
  lastName: String,
  avatarColor: String,
  medium: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  light: {
    type: Boolean,
    default: false
  }
})

const content = computed(() => (((props.data?.first_name ?? props.firstName)?.[0] ?? '') + ((props.data?.last_name ?? props.lastName)?.[0] ?? '')).toUpperCase())
</script>

<style scoped lang="scss">
.avatar-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background: var(--color-primary);
  color: var(--color-bg);
  user-select: none;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &.medium {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  &.small {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
  }
  &.light {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0;
  }

  .icon {
    width: 40%;
    height: 40%;
  }
}
</style>
