import { useThemeCookie } from '~/composables/cookie'

export default function useTheme () {
  const theme = useThemeCookie()

  const htmlAttrs = computed(() => ({ 'data-theme': theme.value }))
  useHead({ htmlAttrs })

  return theme
}
