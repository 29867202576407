function mapObject (obj, fn) {
  return Object.keys(obj).reduce(
    (res, key) => {
      res[key] = fn(obj[key])
      return res
    },
    {}
  )
}

export function deepMap (obj, fn) {
  const deepMapper = val => typeof val === 'object' ? deepMap(val, fn) : fn(val)
  if (Array.isArray(obj)) {
    return obj.map(deepMapper)
  }
  if (typeof obj === 'object' && obj !== null) {
    return mapObject(obj, deepMapper)
  }
  return obj
}
