
export default function useBreakpoints (props = { server: process.server }) {
  const { isMobile: deviceIsMobile } = useDevice()
  const { width } = useWindowSize()

  const isMobile = computed(() => !props.server && width.value ? width.value <= 920 : deviceIsMobile)

  return {
    isMobile
  }
}
