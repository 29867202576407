import { authRoutes } from '~/routes/auth'
import { userRoutes } from '~/routes/user'
import { projectRoutes } from '~/routes/project'
import { miscRoutes } from '~/routes/misc'
import { mentoringRoutes } from '~/routes/mentoring'
import { materialsRoutes } from '~/routes/materials'
import { paymentRoutes } from '~/routes/payment'
import { blogRoutes } from '~/routes/blog'
import { businessRoutes } from '~/routes/business'

export const routes = [
  ...authRoutes,
  ...userRoutes,
  ...projectRoutes,
  ...miscRoutes,
  ...mentoringRoutes,
  ...materialsRoutes,
  ...paymentRoutes,
  ...blogRoutes,
  ...businessRoutes,
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: { public: true }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '@/views/Privacy.vue'),
    meta: { public: true }
  },
  {
    path: '/requisites',
    name: 'requisites',
    component: () => import(/* webpackChunkName: "requisites" */ '@/views/Requisites.vue'),
    meta: { public: true }
  },
  {
    path: '/offer',
    name: 'offer',
    component: () => import(/* webpackChunkName: "offer" */ '@/views/Offer.vue'),
    meta: { public: true }
  },
  {
    path: '/reference',
    name: 'reference',
    component: () => import(/* webpackChunkName: "offer" */ '@/views/Reference.vue'),
    meta: { public: true }
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import(/* webpackChunkName: "offer" */ '@/views/NotFound.vue')
  }
]
