
/**
 * TODO: Remove when is fixed
 * https://github.com/nuxt/nuxt/issues/13020#issuecomment-1397282717
 */
export function useStatefulCookie (name, options = {}) {
  const cookie = useCookie(name, options)
  const state = useState(`cookie-${name}`, () => cookie.value)

  const callback = () => (cookie.value = state.value)

  watch(state, callback, { deep: true })

  return state
}
