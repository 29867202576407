
export function useSafeGoBack (fallbackRoute = { name: 'home' }) {
  const router = useRouter()
  const isAuthorized = inject('isAuthorized')

  return () => {
    const backPath = router.options.history.state.back
    const backRoute = backPath ? router.resolve(backPath) : null
    if (!backRoute || backRoute.meta?.soon || (!backRoute?.meta?.public && !isAuthorized.value)) return router.replace(fallbackRoute)
    router.back()
  }
}

export function useMyProfileRoute () {
  const user = inject('user')
  return computed(() => ({ name: 'user-profile', params: { id: user.value?.id } }))
}

export function useBecomeRoleRoute (role) {
  const user = inject('user')
  const myProfileRoute = useMyProfileRoute()
  return computed(
    () => user.value
      ? { ...myProfileRoute.value, hash: `#${role}` }
      : { name: 'auth-register-advanced', params: { role } }
  )
}
export function useBecomeMentorRoute () { return useBecomeRoleRoute('mentor') }
export function useBecomeSpecialistRoute () { return useBecomeRoleRoute('specialist') }
