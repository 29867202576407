export const userRoutes = [
  {
    path: '/profile/:id',
    name: 'user-profile',
    redirect: to => ({ name: 'mentor-profile', params: { id: to.params.id } }),
    meta: { public: true }
  },
  {
    path: '/user/settings',
    name: 'user-settings',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/UserSettings')
  },
  {
    path: '/user/notifications',
    name: 'user-notifications',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/NotificationsList')
  }
]
